import { Form, notification } from 'antd'
import dayjs from 'dayjs'
import _, { cloneDeep, uniq } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { RootState } from '../../app/store'
import CLOSE_ICON from '../../assets/images/close-success.png'
import SUCCESS from '../../assets/images/success.png'
import Define from '../../constants/define'
import { ActionType, StatusAPI, TableType } from '../../enum'
import { CommandeScene } from '../../enum/CommandeScene'
import { StatusCommande } from '../../enum/StatusCommande'
import { sourceType } from '../../enum/sourceType'
import {
  addEmptyLineWhenInit,
  addOrChangeValueInLine,
  convertFromDetailDataOfApi,
  getLineDiscardedWithoutNewLine,
  getLineWithSourceFromDataTable,
  getValueFromLine,
  mapCdnToData,
  mapDescriptionJsonToProperty_comFiles,
  removeFakeId,
} from '../../features/stock/modal/type/commande/func'
import { NameAndValue } from '../../features/stock/stockscreen/component/Wrapper'
import { DataTableParam } from '../../features/stock/stockscreen/component/model'
import commandeApi from '../../http/commandeApi'
import referenceApi, { EntryFind } from '../../http/referenceApi'
import {
  Adresses,
  AttCommon,
  CommandeRequest,
  CommandeResponse,
  Contact,
  FileResponse,
} from '../../models'
import { getAllCommande } from '../../redux/reducers/commandeSlice'
import {
  clearCurrentDestinataire,
  getDestinataireByCode,
} from '../../redux/reducers/destinataireSlice'
import {
  clearCurrentTransporteur,
  getTransporteurByCode,
} from '../../redux/reducers/transporteurSlice'
import { convertDataTabletoAPI } from '../../utils/commande/convertDataTabletoAPI'
import { getCurrentFiltersText } from '../../utils'
import { checkIfClientWarehouseCompanyCorrespond } from '../../features/stock/stockscreen/func'

const useCommandeEdit = () => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const subpath = location.pathname
  const pageIndex = Number(searchParams.get('page-index')) || 1
  const pageSize = Number(searchParams.get('page-size')) || 25
  const count = Number(searchParams.get('count')) || 0
  const mustSearch = searchParams.get('must-search') === 'true'
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const [attDestinataire, setAttDestinataire] = useState<AttCommon>()
  const [attTransporteur, setAttTransporteur] = useState<AttCommon>()

  const { currentTransporteurList, currentTransporteur } = useSelector(
    (state: RootState) => state.transporteur
  )
  const { currentDestinataire } = useSelector(
    (state: RootState) => state.destinataire
  )
  const [dataDestinataireAdresses, setDataDestinataireAdresses] = useState<
    Adresses[]
  >([])
  const [dataDestinataireContact, setDataDestinataireContact] = useState<
    Contact[]
  >([])
  const [isCreateTransporteur, setIsCreateTransporteur] =
    useState<boolean>(false)
  const [isCreateDestinataire, setIsCreateDestinataire] =
    useState<boolean>(false)
  const [gln, setGln] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [cmdFile, setCmdFile] = useState<FileResponse[]>([])
  const [oldCmdFile, setOldCmdFile] = useState<FileResponse[]>([])
  const [fileIdsDelete, setFileIdsDelete] = useState<string[]>([])
  const [cmdResponse, setCmdResponse] = useState<CommandeResponse>()
  const [detailData, setDetailData] = useState<DataTableParam[][]>([])
  const [detailDataFromApi, setDetailDataFromApi] = useState<
    DataTableParam[][]
  >([])
  const [detailLineDeletedIds, setDetailLineDeletedIds] = useState<string[]>([])
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [isValidDetail, setIsValidDetail] = useState<boolean>(true)
  const [isEmptyTransporteur, setIsEmptyTransporteur] = useState<boolean>(false)
  const [isEmptyDestinataire, setIsEmptyDestinataire] = useState<boolean>(false)
  const [statusCode, setStatusCode] = useState<StatusCommande>()
  const [displayRelay, setDisplayRelay] = useState<boolean>(false)
  const [activeLot, setActiveLot] = useState<boolean>(true)
  const [changedDetailData, setChangedDetailData] = useState<boolean>(false)
  const [isSearchStock, setIsSearchStock] = useState<boolean>(false)
  const [forceSearchStock, setForceSearchStock] = useState<boolean>(false)
  const [searchData, setSearchData] = useState<DataTableParam[][]>([])
  //get commande by id
  useEffect(() => {
    if (!params.id) return
    setInitLoading(true)
    setChangedDetailData(false)
    commandeApi
      .getCommandeById({ id: params.id })
      .then((res) => {
        // res.data.entry.scene = CommandeScene.EditScene;
        let result = res.data.entry
        // if 3 warehouse information are different from 3 warehouse chosen from search bar then navigate to dashboard
        if (!checkIfClientWarehouseCompanyCorrespond(result)) {
          return navigate('/tableau-de-bord')
        }
        const scene = result.scene
        let dataList = convertFromDetailDataOfApi(result.com_article || [])
        dataList = dataList.map((item) => {
          // item = item.filter(f => f.name !=='dluo');
          if (!item.find((item) => item.name === 'motif'))
            item.push({ name: 'motif', value: '' })
          if (!item.find((item) => item.name === 'sscc_block'))
            item.push({ name: 'sscc_block', value: '[]' })
          if (!item.find((item) => item.name === 'sscc_motif'))
            item.push({ name: 'sscc_motif', value: '[]' })
          if (!item.find((item) => item.name === 'ruptures_check_lot')) {
            const qte = item.find((item) => item.name === 'qte')?.value
            if (scene === CommandeScene.EditScene)
              item.push({ name: 'ruptures_check_lot', value: qte })
            else item.push({ name: 'ruptures_check_lot', value: '0' })
          }

          if (!item.find((item) => item.name === 'is_manual_sscc'))
            item.push({ name: 'is_manual_sscc', value: '0' })
          if (!item.find((item) => item.name === 'is_manual_lot'))
            item.push({ name: 'is_manual_lot', value: '0' })
          if (!item.find((item) => item.name === 'is_manual_dluo'))
            item.push({ name: 'is_manual_dluo', value: '0' })

          addOrChangeValueInLine(
            { name: 'qte_confirmee_search', value: '-' },
            item
          )
          addOrChangeValueInLine({ name: 'motif_search', value: '-' }, item)
          addOrChangeValueInLine({ name: 'manquants_search', value: '-' }, item)
          return item
        })
        dataList = addEmptyLineWhenInit(dataList, scene)

        setDetailDataFromApi(dataList)

        // setDetailData(dataList)
        setCmdResponse(result)
        setStatusCode(result.status_code)
        setActiveLot(result.active_lot || false)

        //set fields value
        form.setFieldsValue({
          nom: result.nom,
          nom_client: result.nom_client,
          client: result.client_code_nom,
          priorite: result.priorite || 5,
          transporteur: result.transporteur
            ? `${result.transporteur?.code}_${result.transporteur?.nom}`
            : 'Enlèvement client',

          destinataire: result.destinataire
            ? `${result.destinataire?.code}_${result.destinataire?.nom}`
            : null,
          chargement_at: result.chargement_at
            ? dayjs.unix(result.chargement_at)
            : null,
          livraison_at: result.livraison_at
            ? dayjs.unix(result.livraison_at)
            : null,
          imperatif: result.imperatif || false,
          relay_detail: result.relay_detail,
          com_preparation: {
            comment: result.com_preparation?.comment,
            id: result.com_preparation?.id,
            commande_id: result.com_preparation?.commande_id,
          },
          com_livraison: {
            comment: result.com_livraison?.comment,
            id: result.com_livraison?.id,
            commande_id: result.com_livraison?.commande_id,
          },
        })
        if (result.tran_addresse?.gln) {
          setGln(result.tran_addresse?.gln)
        } else setGln('')

        if (result.transporteur?.relay) {
          setDisplayRelay(true)
        }

        //set table value
        setAttDestinataire({
          destinataire_id: result.destinataire?.id,
          address_id: result.des_addresse?.id,
          contact_id: result.des_contact?.id,
        })
        setAttTransporteur({
          transporteur_id: result.transporteur?.id,
          address_id: result.tran_addresse?.id,
          contact_id: result.tran_contact?.id,
        })
        if (!result.des_addresse) {
          setDataDestinataireAdresses([])
        } else setDataDestinataireAdresses([result.des_addresse])
        if (!result.des_contact) {
          setDataDestinataireContact([])
        } else {
          setDataDestinataireContact([result.des_contact])
        }

        //set documents(files) value
        if (result.com_file && result.com_file?.length > 0) {
          let com_file = result.com_file.sort((a, b) => {
            if (b?.created_at !== a?.created_at) {
              return (b?.created_at || 0) - (a?.created_at || 0)
            } else {
              return a?.name?.localeCompare(b?.name)
            }
          })
          let new_com_file = mapDescriptionJsonToProperty_comFiles(com_file)

          setCmdFile(new_com_file)
          setOldCmdFile(com_file)
        }

        setInitLoading(false)
      })
      .finally(() => {
        setIsValidDetail(true)
      })
  }, [params.id, count])

  useEffect(() => {
    if (!mustSearch) return
    if (!params?.id) return
    if (detailData.length === 0) return

    searchStock(true, true)
  }, [mustSearch, params?.id, detailData])

  //set relay detail null
  useEffect(() => {
    if (
      !currentTransporteurList.loading ||
      currentTransporteur.status === StatusAPI.calling
    ) {
      form.setFieldValue('relay_detail', '')
    }
  }, [form, currentTransporteurList.loading, currentTransporteur.status])

  useEffect(() => {
    if (isCreateTransporteur) {
      form.setFieldValue('relay_detail', '')
    }
  }, [form, isCreateTransporteur])

  useEffect(() => {
    if (currentTransporteur.transporteur?.relay) {
      setDisplayRelay(true)
      form.setFieldValue('relay_detail', '')
    } else {
      setDisplayRelay(false)
    }
  }, [currentTransporteur.transporteur, form])

  //set attDestinataire if find a destinataire after search
  useEffect(() => {
    if (currentDestinataire.destinataire) {
      setDataDestinataireAdresses(
        currentDestinataire.destinataire?.addresse as Adresses[]
      )
      setDataDestinataireContact(
        currentDestinataire.destinataire?.contact as Contact[]
      )

      setAttDestinataire({
        destinataire_id: currentDestinataire.destinataire?.id,
        address_id: currentDestinataire.destinataire?.addresse[0].id,
        contact_id: currentDestinataire.destinataire?.contact[0].id,
      })
    } else {
      setAttDestinataire({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDestinataire.action, currentDestinataire.destinataire])

  //set transporteur field value after search
  useEffect(() => {
    if (!currentTransporteur.transporteur) return

    form.setFieldValue(
      'transporteur',
      `${currentTransporteur.transporteur?.code}_${currentTransporteur.transporteur?.nom}`
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransporteur, isCreateTransporteur])

  //set destinataire field value after search
  useEffect(() => {
    if (!currentDestinataire.destinataire) return
    form.setFieldValue(
      'destinataire',
      `${currentDestinataire.destinataire?.code}_${currentDestinataire.destinataire?.nom}`
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDestinataire, isCreateDestinataire])

  //select the lastest created gln
  useEffect(() => {
    let selectedGLN = _.findLast(
      currentTransporteur.transporteur?.addresse,
      (item) => item !== undefined
    )
    setGln(selectedGLN?.gln as string)
  }, [currentTransporteur.transporteur?.addresse])

  //set attTransporteur if find a transporteur after search
  useEffect(() => {
    if (currentTransporteur.transporteur) {
      let currentTrans = currentTransporteur.transporteur
      setAttTransporteur({
        transporteur_id: currentTrans.id,
        address_id: currentTrans.addresse[currentTrans.addresse.length - 1].id,
        contact_id: currentTrans.contact[currentTrans.contact.length - 1].id,
      })
      setIsEmptyTransporteur(false)
    } else {
      setAttTransporteur({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransporteur.action, currentTransporteur.transporteur])

  useEffect(() => {
    if (currentDestinataire.destinataire) {
      setIsEmptyDestinataire(false)
    }
  }, [currentDestinataire.destinataire])

  //noti success if create transporteur success
  useEffect(() => {
    if (
      currentTransporteur.status === StatusAPI.success &&
      currentTransporteur.action === ActionType.CREATE
    ) {
      notification.open({
        className: 'noti noti-success',
        message: (
          <div className="flex items-center">
            <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
            Succès: Création enregistrée
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      setIsCreateTransporteur(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransporteur.status])

  //noti success if create destinataire success
  useEffect(() => {
    if (
      currentDestinataire.status === StatusAPI.success &&
      currentDestinataire.action === ActionType.CREATE
    ) {
      notification.open({
        className: 'noti noti-success',
        message: (
          <div className="flex items-center">
            <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
            Succès: Création enregistrée
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      setIsCreateDestinataire(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDestinataire.status])

  useEffect(() => {
    setDetailLineDeletedIds([])
  }, [count])

  //handle attDestinataire when onclick radio button
  const handleAttDestinataire = (type: string, value: any) => {
    let myAttDestinataire = attDestinataire || {}
    switch (type) {
      case TableType.ADDRESSE:
        myAttDestinataire.address_id = value.id
        myAttDestinataire.destinataire_id = value.destinataire_id

        break
      case TableType.CONTACT:
        myAttDestinataire.contact_id = value.id
        myAttDestinataire.destinataire_id = value.destinataire_id

        break
      default:
        break
    }
    setAttDestinataire(myAttDestinataire)
  }
  //search transporteur by code
  const onChangeTransporteur = (newValue: NameAndValue, searchCode: string) => {
    const code = newValue.name
    if (code) {
      if (code === 'Enlèvement client') {
        setAttTransporteur({})
        setGln('')
        setDisplayRelay(false)
        return
      }
      dispatch(getTransporteurByCode({ code: searchCode }))
    }
  }

  //search destinataire by code
  const onChangeDestinataire = (newValue: NameAndValue, code: string) => {
    if (newValue.name) {
      dispatch(getDestinataireByCode({ code }))
    }
  }

  //get files change from document
  const onChangeDataFile = (data: FileResponse[]) => {
    let newData = data.sort(
      (a, b) => (b?.created_at || 0) - (a?.created_at || 0)
    )
    setCmdFile(newData)
  }

  //get deleted files ids form document
  const onDeleteFile = (id: string) => {
    setFileIdsDelete([...fileIdsDelete, id])
  }

  //close modal
  const handleClose = () => {
    dispatch(clearCurrentDestinataire())
    dispatch(clearCurrentTransporteur())
    dispatch(getAllCommande({ isRealTime: false }))

    navigate(
      `/gestion-de-stock/commande?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
    )
  }

  const onChangeDataDetail = (
    value: DataTableParam[][],
    scene?: CommandeScene
  ) => {
    setChangedDetailData(true)
    let listDetailLineIdMustRemove = []
    for (let i = 0; i < value.length; i++) {
      const line = value[i]

      const sscc = line.find((item) => item.name === 'sscc')
      const id = line.find((item) => item.name === 'id')?.value

      if (!sscc || !id) continue

      if (sscc.mustRemove || sscc.mustRefresh)
        listDetailLineIdMustRemove.push(id)
    }

    const lineDiscarded = getLineDiscardedWithoutNewLine(detailData, value)
    let lineDiscardedIds: string[] = []
    if (lineDiscarded.every((line) => getValueFromLine('id', line))) {
      lineDiscardedIds = lineDiscarded.map(
        (line) => getValueFromLine('id', line) || ''
      )
    }

    listDetailLineIdMustRemove = uniq(
      lineDiscardedIds
        .concat(detailLineDeletedIds)
        .concat(listDetailLineIdMustRemove)
    )
    setDetailLineDeletedIds(listDetailLineIdMustRemove)
    const data =
      scene === CommandeScene.EditScene
        ? value
        : addEmptyLineWhenInit(
            value.map((item) => {
              // item = item.filter(f => f.name !=='dluo');
              if (!item.find((item) => item.name === 'motif'))
                item.push({ name: 'motif', value: '' })
              if (!item.find((item) => item.name === 'sscc_block'))
                item.push({ name: 'sscc_block', value: '[]' })
              if (!item.find((item) => item.name === 'sscc_motif'))
                item.push({ name: 'sscc_motif', value: '[]' })
              if (!item.find((item) => item.name === 'ruptures_check_lot')) {
                const qte = item.find((item) => item.name === 'qte')?.value
                item.push({ name: 'ruptures_check_lot', value: qte })
              }

              if (!item.find((item) => item.name === 'is_manual_sscc'))
                item.push({ name: 'is_manual_sscc', value: '0' })
              if (!item.find((item) => item.name === 'is_manual_lot'))
                item.push({ name: 'is_manual_lot', value: '0' })
              if (!item.find((item) => item.name === 'is_manual_dluo'))
                item.push({ name: 'is_manual_dluo', value: '0' })

              // item.push({name: 'dluo', value: randomDate[Math.floor(Math.random() * 6)], bold: true});
              return item
            })
          )
    setDetailData(data)
  }

  const onChangeForcerDluo = (value: boolean) => {
    setCmdResponse({ ...cmdResponse, force_dlou: value })
  }

  const onChangePhotos = (value: number) => {
    setCmdResponse({ ...cmdResponse, photos: value })
  }

  const onDeleteLineDetail = (id: string) => {
    let listDelete = cloneDeep(detailLineDeletedIds)
    listDelete.push(id)
    listDelete = uniq(listDelete)
    setDetailLineDeletedIds(listDelete)
  }

  const onCheckValid = (isValid: boolean) => {
    setIsValidDetail(isValid)
  }

  const checkLastest = async (payload: { id: string; updated_at: number }) => {
    let status: boolean
    const response = (
      await commandeApi.checkLastest({
        id: payload.id,
        updated_at: payload.updated_at,
      })
    ).data.status

    if (response === '200') status = true
    else status = false
    return status
  }

  const searchStock = async (forceDluo?: boolean, firstSearch?: boolean) => {
    if (!params?.id) return
    setIsSearchStock(true)
    const entry = (await commandeApi.getCommandeById({ id: params.id })).data
      .entry

    const request: CommandeRequest = {
      entry: cloneDeep(entry),
      details_delete: { id: [] },
    }

    request.entry.destinataire_id = attDestinataire?.destinataire_id
    request.entry.des_addresse_id = attDestinataire?.address_id
    request.entry.des_contact_id = attDestinataire?.contact_id
    request.entry.destinataire = undefined
    request.entry.active_lot = activeLot
    // delete request.entry.destinataire?.code;
    // delete request.entry.destinataire?.nom;
    // delete request.entry.destinataire?.nom;
    // delete request.entry.destinataire?.nom;

    if (firstSearch) request.entry.force_dlou = entry.force_dlou
    else request.entry.force_dlou = forceDluo

    const detailDataManual = getLineWithSourceFromDataTable(
      detailData,
      sourceType.MANUAL
    )
    removeFakeId(detailDataManual)
    let detailDataMapping = convertDataTabletoAPI(detailDataManual, false, true)
    for (let i = 0; i < detailDataMapping.length; i++) {
      detailDataMapping[i].created_by = entry.created_by
      detailDataMapping[i].creater_id = entry.creater_id
      detailDataMapping[i].modification_by =
        localStorage.getItem(Define.USERNAME) || ''
      detailDataMapping[i].modificationer_id =
        localStorage.getItem(Define.USER_ID) || ''
      detailDataMapping[i].status_code = StatusCommande.CreatedCommande
      detailDataMapping[i].active_lot = activeLot
      detailDataMapping[i].commande_created_at = entry?.created_at
    }
    request.details_delete = { id: detailLineDeletedIds }
    const detailDataMappingSearch = detailDataMapping.map((line) => {
      if (line.sscc_block) delete line.sscc_block
      return line
    })
    request.entry.com_article = detailDataMappingSearch

    commandeApi
      .searchStock(request)
      .then((res) => {
        let dataList = convertFromDetailDataOfApi(
          res.data.entry.com_article || [],
          true
        )
        const listRefNom = uniq(
          dataList.map((line) => getValueFromLine('reference', line))
        )
        setCmdResponse({
          ...cmdResponse,
          com_article: res.data.entry.com_article,
        })

        Promise.all(
          listRefNom.map((refNom) => referenceApi.findByNum(refNom || ''))
        ).then((values) => {
          let references: EntryFind[] = values.map((item) => item.data.entry)
          mapCdnToData(dataList, references || [])

          dataList = dataList.map((line) => {
            const manquants = getValueFromLine('manquants', line)
            const qteConfirm = getValueFromLine('qte_confirmee', line)
            const motif = getValueFromLine('motif', line)
            const qte = getValueFromLine('qte', line)

            addOrChangeValueInLine(
              { name: 'manquants_search', value: manquants },
              line
            )
            addOrChangeValueInLine(
              { name: 'qte_confirmee_search', value: qteConfirm },
              line
            )
            addOrChangeValueInLine({ name: 'motif_search', value: motif }, line)
            addOrChangeValueInLine(
              { name: 'ruptures_check_lot', value: qte },
              line
            )

            return line
          })

          // dataList = removeIdOfLineNotSaveYet(dataList, newLineNotSaveYet);

          setSearchData(dataList)
          setForceSearchStock(false)
        })
      })
      .finally(() => {
        setIsSearchStock(false)
        navigate(
          `${subpath}?page-index=${pageIndex}&page-size=${pageSize}&must-search=false${getCurrentFiltersText(
            ['must-search']
          )}`
        )
      })
  }
  return {
    form,
    setSubmitted,
    currentDestinataire,
    isValidDetail,
    cmdResponse,
    loading,
    initLoading,
    setLoading,
    detailData,
    detailDataFromApi,
    attDestinataire,
    attTransporteur,
    cmdFile,
    oldCmdFile,
    detailLineDeletedIds,
    fileIdsDelete,
    isCreateTransporteur,
    isEmptyTransporteur,
    isCreateDestinataire,
    isEmptyDestinataire,
    currentTransporteur,
    dataDestinataireAdresses,
    dataDestinataireContact,
    gln,
    displayRelay,
    submitted,
    statusCode,
    changedDetailData,
    isSearchStock,
    searchData,
    setCmdFile,
    setDetailDataFromApi,
    setCmdResponse,
    setDetailData,
    setIsEmptyTransporteur,
    setIsCreateTransporteur,
    setIsEmptyDestinataire,
    setIsCreateDestinataire,
    onCheckValid,
    onDeleteLineDetail,
    onChangePhotos,
    onChangeDataDetail,
    handleClose,
    onDeleteFile,
    onChangeDataFile,
    onChangeDestinataire,
    onChangeTransporteur,
    handleAttDestinataire,
    activeLot,
    setActiveLot,
    setDetailLineDeletedIds,
    setChangedDetailData,

    setIsValidDetail,
    checkLastest,
    setIsSearchStock,
    forceSearchStock,
    setForceSearchStock,
    searchStock,
    setSearchData,
    onChangeForcerDluo,
  }
}

export default useCommandeEdit
