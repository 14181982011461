import { Popconfirm } from 'antd'
import clsx from 'clsx'
import { t } from 'i18next'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { DocumentStatus, DocumentType } from '../../../enum/DocumentStatus'
import { sourceType } from '../../../enum/sourceType'
import { ModalName } from '../../../features/stock/modal/customModalSlice'
import fileApi from '../../../http/fileApi'
import { FileResponse } from '../../../models'
import { convertPhotoType, formatDateTime } from '../../../utils'
import Loading from '../Loading/Loading'
import Documents from './Documents'

export type FileInformationType = {
  id: string
  name: string
  type: DocumentType
}

interface DisplayDocumentsCmdProps {
  /**
   * show import document
   */
  openImport?: boolean
  /**
   * name of the modal which display document
   */
  type?: ModalName
  /**
   * show data change from document
   * @param newValue data change
   * @param deleteId id of removed document
   * @returns
   */
  onChangeData?: (newValue: FileResponse[], deleteId?: string) => void
  /**
   * return id of removed file
   * @param id id of removed file
   * @returns
   */
  onDeleteFile?: (id: string) => void
  /**
   * close import popup
   * @returns void
   */
  closeImport?: () => void
  /**
   * init data of document
   */
  initData?: FileResponse[]
  /**
   * check if commande is edit or sent on preparation
   */
  isSendOnPreparation?: boolean
  /**
   * function click on button Enoyer en mission
   * @param fileId file id
   * @param fileName file name
   * @returns
   */
  onEnoyerEnMission: (file: Array<FileInformationType>) => void
  /**
   * check if allow envoyer en mission
   */
  isAllowEnvoyerEnMission?: boolean
  /**
   * file cancel at (timestamp)
   */
  cancelledAt?: number
  /**
   * function click on button Enoyer en chargement
   * @param fileId fileId
   * @returns
   */
  onEnvoyerChargement?: (fileId: string) => void
}

/**
 * The @DisplayDocumentsCmd is a component display and config files of commande modal
 * @author anhnq
 * @param props
 * @returns
 */
const DisplayDocumentsCmd = (props: DisplayDocumentsCmdProps) => {
  const commandeKey = useSelector((state: RootState) => state.selector).data
    .commande
  const {
    type = ModalName.ATTENDU,
    onChangeData = () => {},
    onDeleteFile = () => {},
    openImport = false,
    closeImport = () => {},
    initData = [],
    isSendOnPreparation = false,
    cancelledAt,
    onEnvoyerChargement = () => {},
  } = props
  const [loadingUploadFile, setLoadingUploadFile] = useState<boolean>(false)

  const baseURL = process.env.REACT_APP_API_ENDPOINT

  const onConfirmImport = (data: any) => {
    closeImport()
    setLoadingUploadFile(true)

    let formData = new FormData()
    for (let i = 0; i < data.length; i++) {
      formData.append('files', data[i])
    }

    //call api upload files
    fileApi
      .uploadMultiCommonFiles({ type, file: formData })
      .then((res) => {
        setLoadingUploadFile(false)
        // remove tag showEnvoyer and add new file to current files
        const newData = [...initData, ...res.data.entry]
        onChangeData(newData)
      })
      .catch((e) => {
        setLoadingUploadFile(false)
        console.log(e)
      })
  }

  const printFile = (pathUrl: string) => {
    if (pathUrl) {
      let path = `${baseURL}/files/common/show/${type}/${pathUrl}`
      //open new window
      let mywindow = window.open(path, '_blank')
      if (mywindow) {
        //display file in new window
        mywindow.document.write(
          '<html><head><title>' + document.title + '</title>'
        )
        mywindow.document.write('</head><body >')
        if (
          path.toLowerCase().includes('jpg') ||
          path.toLowerCase().includes('png') ||
          path.toLowerCase().includes('jpeg')
        ) {
          mywindow.document.write(`<img src=${path} alt=${path}/>`)
        }
        if (path.toLowerCase().includes('pdf')) {
          mywindow.document.write(`<embed
                type="application/pdf"
                src=${path}
                
                width="100%"
                height="100%" />`)
        }

        mywindow.document.write('</body></html>')

        mywindow.document.close() // necessary for IE >= 10

        mywindow.onload = function () {
          // wait until all resources loaded
          if (path.toLowerCase().includes('pdf')) {
            setTimeout(function () {
              mywindow?.focus() // necessary for IE >= 10
              mywindow?.print() // change window to mywindow
            }, 3000)
          } else {
            mywindow?.focus() // necessary for IE >= 10
            mywindow?.print() // change window to mywindow
            mywindow?.close() // change window to mywindow
          }
        }
      }
    }
  }

  const removeFileItem = (id: string, path: string) => {
    //delete file through api if file not have id
    if (!id) {
      fileApi
        .deleteFile(path)
        .then((res) => {
          const newData = cloneDeep(initData).filter(
            (item) => item.path !== path
          )
          onChangeData(newData)
        })
        .catch((e) => alert(e))
    } else {
      //if file have id, delete directly
      const newData = cloneDeep(initData).filter((item) => item.path !== path)

      if (isSendOnPreparation) onChangeData(newData, id)
      else {
        onChangeData(newData)
        onDeleteFile(id)
      }
    }
  }

  const listFileRender = convertPhotoType(cloneDeep(initData)).uploadFile

  function onClickEnvoyer() {
    if (props?.isAllowEnvoyerEnMission) {
      const shouldSendFiles = listFileRender
        .filter(
          (el) =>
            el.source === sourceType.AUTO.toString() &&
            el.statut === DocumentStatus.Ajour.toString()
        )
        //@ts-ignore
        .reduce((list, item) => {
          const description = JSON.parse(item.description || '{}')
          return [
            ...list,
            { id: item.id, name: description.fileName, type: description.type },
          ]
        }, [])
      //@ts-ignore
      props.onEnoyerEnMission(shouldSendFiles)
    }
  }

  const renderActionCol = (el: FileResponse, index: number) => {
    const description = JSON.parse(el.description || '{}')
    // if commande had been cancelled
    if (
      el.source === sourceType.AUTO.toString() &&
      el.cancel_at &&
      el.statut === DocumentStatus.Annule.toString()
    ) {
      return (
        <td>
          <p>{formatDateTime(el.created_at || 0)}</p>
        </td>
      )
    }
    if (
      el.source === sourceType.AUTO.toString() &&
      el.statut === DocumentStatus.Envoye.toString()
    ) {
      return (
        <td>
          <div className="flex items-center justify-center w-full">
            <span className="not-italic">
              {moment(description.sendMissionPrAt).format(t('time-format'))}
            </span>
          </div>
        </td>
      )
    }
    if (
      el.source === sourceType.AUTO.toString() &&
      el.statut !== DocumentStatus.Ajour.toString() &&
      el.statut !== DocumentStatus.Envoye.toString() &&
      el.statut !== DocumentStatus.Effectuee.toString()
    ) {
      return (
        <td>
          <div className="flex items-center justify-center w-full">
            <i className="icon-auto mr-2"></i>
            <span className="not-italic">auto</span>
          </div>
        </td>
      )
    }

    if (
      el.source === sourceType.AUTO.toString() &&
      el.statut === DocumentStatus.Ajour.toString()
    ) {
      if (el.showEnvoyer) {
        const nextItemDescription = listFileRender[index + 1]
        return (
          <td
            rowSpan={
              nextItemDescription?.statut === DocumentStatus.Ajour ? 2 : 1
            }
          >
            <button
              id={commandeKey['envoyer-mission']}
              data-next-id={`${commandeKey['code-nom-transporter']}, ${commandeKey['code-nom-destinataire']}, ${commandeKey['annuler']}, ${commandeKey['Sauvegarder']}`}
              data-previous-id={`commandeicon-edit-0, ${commandeKey['Forcer-la-DLUO']}`}
              className={clsx(
                'border-solid text-xs font-semibold  pl-5 pr-5 py-1 rounded-lg relative',
                props?.isAllowEnvoyerEnMission
                  ? 'border-[#01E37A] text-white hover:text-white bg-[#01E37A] cursor-pointer'
                  : 'border-gray text-gray hover:text-gray bg-white cursor-no-drop'
              )}
              onClick={onClickEnvoyer}
              disabled={!props?.isAllowEnvoyerEnMission}
            >
              Envoyer en mission
            </button>
          </td>
        )
      } else {
        return <></>
      }
    }

    if (
      el.source === sourceType.AUTO.toString() &&
      el.statut === DocumentStatus.Effectuee.toString()
    ) {
      return (
        <td>
          <button
            className=" border-[#01E37A] border-solid bg-white text-[#28A76C] hover:text-[#28A76C] cursor-pointer text-xs font-semibold  pl-5 pr-5 py-1 rounded-lg relative"
            onClick={() => {
              sendMissionID(el.id || '')
            }}
          >
            Envoyer en chargement
          </button>
        </td>
      )
    }

    if (el.source !== sourceType.AUTO.toString()) {
      return (
        <td>
          <Popconfirm
            title=""
            description="Êtes-vous sûr de vouloir supprimer cet élément?"
            onConfirm={() => removeFileItem(el.id || '', el.path)}
            okText="Supprimer"
            cancelText="Annuler"
            icon={null}
          >
            <i className="delete"></i>
          </Popconfirm>
        </td>
      )
    }
  }

  const sendMissionID = (fileId: string) => {
    onEnvoyerChargement(fileId)
  }

  return (
    <>
      <Documents
        isOpen={openImport}
        onClose={closeImport}
        onConfirm={onConfirmImport}
      />

      <table className="table-document">
        <thead>
          <tr>
            <th className="form-label">Type</th>
            {isSendOnPreparation && <th className="form-label">Date</th>}
            <th className="form-label">Voir</th>
            <th className="form-label">Télécharger</th>
            <th className="form-label">Imprimer</th>
            {(isSendOnPreparation || cancelledAt) && (
              <th className="form-label">Statut</th>
            )}
            <th className="form-label">Actions</th>
          </tr>
        </thead>
        {loadingUploadFile ? (
          <Loading />
        ) : (
          <tbody>
            {listFileRender.map((el, index) => (
              <tr key={el.id}>
                <td>
                  {el.description
                    ? JSON.parse(el.description)?.displayName || el.name
                    : el.name}
                </td>
                {isSendOnPreparation && (
                  <td>
                    {el.created_at
                      ? moment(Number(el.created_at) * 1000).format(
                          t('time-format')
                        )
                      : '-'}
                  </td>
                )}
                <td>
                  <a
                    className="view"
                    href={`${baseURL}/files/common/show/${type}/${el.path}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    a
                  </a>
                </td>
                <td>
                  <a
                    className="export"
                    download
                    href={`${baseURL}/files/common/${type}/${el.path}`}
                  >
                    a
                  </a>
                </td>

                <td>
                  <i className="printer" onClick={() => printFile(el.path)}></i>
                </td>

                {(isSendOnPreparation || cancelledAt) && (
                  <td>{el.statut || '-'}</td>
                )}
                {renderActionCol(el, index)}
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </>
  )
}

export default DisplayDocumentsCmd
